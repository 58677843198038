<script setup>
import { ref, reactive, computed } from "vue";
import { commonStore } from "@/store/common";

import { useRouter } from "vue-router";
const common_store = commonStore();

const router = useRouter();
const activeIndex = computed({
  get() {
    return common_store.footerActiveIndex;
  },
  set(val) {
    common_store.footerActiveIndex = val;
  },
});
const navArr = [
  {
    icon: require("@assets/commonImg/icon1.png"),
    activeIcon: require("@assets/commonImg/icon1-active.png"),
    title: "首页",
    url: "/home",
  },
  {
    icon: require("@assets/commonImg/icon2.png"),
    activeIcon: require("@assets/commonImg/icon2-active.png"),
    title: "服务器",
    url: "/market",
  },

  {
    icon: require("@assets/commonImg/icon3.png"),
    activeIcon: require("@assets/commonImg/icon3-active.png"),
    title: "收益",
    url: "/income",
  },

  {
    icon: require("@assets/commonImg/icon4.png"),
    activeIcon: require("@assets/commonImg/icon4-active.png"),
    title: "资产",
    url: "/assets",
  },
];
const toCheck = (obj, key) => {
  activeIndex.value = key;
  router.replace(obj.url);
};
</script>
<template>
  <ul class="flex_between footer">
    <li
      v-for="(item, key) in navArr"
      :key="key"
      @click="toCheck(item, key)"
      class="flex_between column f1 center"
    >
      <div class="nav_icon">
        <img
          :src="item.activeIcon"
          alt=""
          class="nav_icon"
          v-if="activeIndex == key"
        />
        <img :src="item.icon" alt="" class="nav_icon" v-else />
      </div>

      <span
        class="font500 font-Three blockColor"
        :class="[activeIndex == key ? '' : 'opacity6']"
        >{{ item.title }}</span
      >
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.footer {
  height: 80px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 800;
  background: linear-gradient(#e4e4f7 100%, #fff);
  box-shadow: -1px 0 10px 0 #e4e4f7;
  li {
    height: 80px;
    position: relative;
    justify-content: center;
    span {
      margin-top: 6px;
      transition: ease-in-out 0.2s;
    }
  }
}
.nav_icon {
  width: 30px;
  height: 30px;
  transition: ease-in-out 0.2s;
}

.center_icon_top {
  box-shadow: 0 0 4px 0 rgb(136, 135, 135);
}
.center_icon {
  transform: translate(0, -30%);
}
</style>
