<script setup>
import { ref, reactive, onBeforeMount, watch } from "vue";
import mineInfo from "./components/mineInfo.vue";
import banner from "./components/banner.vue";
import navList from "./components/navList.vue";
import { commonStore } from "@/store/common";
import { logOut } from "@/http/account";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

import { Toast } from "vant";
const router = useRouter();

const common_store = commonStore();
const { userInfo } = storeToRefs(common_store);
onBeforeMount(() => {
  common_store.setFooterIndex(4);
  common_store.getUserInfo();
});

//退出登录
const logOutFn = () => {
  logOut({ token: localStorage.getItem("token") }).then((res) => {
    if (res.code == 0) {
      common_store.menuShow = false;
      sessionStorage.setItem("myPopup", 0);
      common_store.logOut();
      router.replace("/");
    }
  });
};
</script>

<template>
  <div class="my Wbg padding">
    <!-- 个人信息 -->
    <mine-info :info="userInfo"></mine-info>

    <!-- 代理级别 -->
    <!-- <banner :info="userInfo"></banner> -->

    <!-- 中心类别 -->
    <nav-list></nav-list>

    <!-- 退出 -->
    <van-button type="primary" block class="my-back Wcolor" @click="logOutFn"
      >退出登录</van-button
    >
  </div>
</template>

<style lang="scss" scoped>
.my {
  width: 85vw;
  height: 100vh;
  background: #f7f7f7;
}
.my-back {
  margin-top: 50px;
}
</style>
