const Home = () => import('@/views/Home/index.vue'); //首页
const Market = () => import('@/views/Market/index.vue'); //服务器
const Income = () => import('@/views/Income/index.vue'); //收益
const Assets = () => import('@/views/Assets/index.vue'); //资产
const My = () => import('@/views/My/index.vue');
const Team = () => import('@/views/My/team.vue'); //团队
const Invite = () => import('@/views/My/invite.vue'); //邀请好友
const Login = () => import('@/views/Account/login.vue');
const Forget = () => import('@/views/Account/forgetPass.vue'); //忘记密码
const Notice = () => import('@/views/Home/noticeCenter.vue'); //公告
const NoticeDetails = () => import('@/views/Home/noticeDetails.vue'); //公告详情
const Set = () => import('@/views/My/set.vue'); //安全中心
const UserSet = () => import('@/views/My/userSet.vue'); //个人设置
const ModityPass = () => import('@/views/My/modityPass.vue'); //修改密码
const RealName = () => import('@/views/My/realName.vue'); //实名认证
const HelpCenter = () => import('@/views/My/helpCenter.vue');
const Ranking = () => import('@/views/My/ranking.vue'); //排行
const CoinDetail = () => import('@/views/Assets/coinDetail.vue'); //币种记录
const Charge = () => import('@/views/Assets/charge.vue'); //充值
const Withdraw = () => import('@/views/Assets/withdraw.vue'); //提现
const MarketDetail = () => import('@/views/Market/detailsInfo.vue'); //服务器详情
const router = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      level: 3.0,
      footerShow: false,
      requireAuth: false,
      keepAlive: true,
      className: 'bg2',
    },
  },
  {
    path: '/forget',
    name: 'Forget',
    component: Forget,
    meta: {
      level: 3.1,
      footerShow: false,
      requireAuth: false,
      keepAlive: true,
      className: 'bg2',
    },
  },

  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      level: 1.5,
      footerShow: true,
      requireAuth: false,
      keepAlive: true,
      className: 'bg2',
    },
  },
  {
    path: '/market',
    name: 'Market',
    component: Market,
    meta: {
      level: 1.6,
      footerShow: true,
      requireAuth: false,
      keepAlive: true,
      className: 'bg2',
    },
  },
  {
    path: '/marketDetail',
    name: 'MarketDetail',
    component: MarketDetail,
    meta: {
      level: 2.0,
      footerShow: false,
      requireAuth: false,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/income',
    name: 'Income',
    component: Income,
    meta: {
      level: 1.7,
      footerShow: true,
      requireAuth: false,
      keepAlive: true,
      className: 'bg2',
    },
  },
  {
    path: '/assets',
    name: 'Assets',
    component: Assets,
    meta: {
      level: 1.8,
      footerShow: true,
      requireAuth: false,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/coinDetail',
    name: 'CoinDetail',
    component: CoinDetail,
    meta: {
      level: 2.0,
      footerShow: false,
      requireAuth: false,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/charge',
    name: 'Charge',
    component: Charge,
    meta: {
      level: 2.1,
      footerShow: false,
      requireAuth: false,
      keepAlive: true,
      className: '',
    },
  },

  {
    path: '/withdraw',
    name: 'Withdraw',
    component: Withdraw,
    meta: {
      level: 2.1,
      footerShow: false,
      requireAuth: false,
      keepAlive: true,
      className: '',
    },
  },

  {
    path: '/my',
    name: 'My',
    component: My,
    meta: {
      level: 1.9,
      footerShow: true,
      requireAuth: true,
      keepAlive: true,
      className: 'bg1',
    },
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    meta: {
      level: 2.0,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: 'bg2',
    },
  },
  {
    path: '/invite',
    name: 'Invite',
    component: Invite,
    meta: {
      level: 2.0,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: Ranking,
    meta: {
      level: 2.0,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice,
    meta: {
      level: 2.0,
      footerShow: false,
      requireAuth: false,
      keepAlive: true,
      className: '',
    },
  },

  {
    path: '/noticeDetails',
    name: 'NoticeDetails',
    component: NoticeDetails,
    meta: {
      level: 2.3,
      footerShow: false,
      requireAuth: false,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/set',
    name: 'Set',
    component: Set,
    meta: {
      level: 2.0,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/modityPass',
    name: 'ModityPass',
    component: ModityPass,
    meta: {
      level: 2.2,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/userSet',
    name: 'UserSet',
    component: UserSet,
    meta: {
      level: 2.1,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: '',
    },
  },
  // {
  //   path: '/realName',
  //   name: 'RealName',
  //   component: RealName,
  //   meta: {
  //     level: 2.1,
  //     footerShow: false,
  //     requireAuth: true,
  //     keepAlive: true,
  //     className: ''
  //   },
  // },
  {
    path: '/helpCenter',
    name: 'HelpCenter',
    component: HelpCenter,
    meta: {
      level: 2.1,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: '',
    },
  },
  // {
  //   path: '/idCard',
  //   name: 'IdCard',
  //   component: () => import('@/views/My/idCard.vue'),
  //   meta: {
  //     level: 2.1,
  //     footerShow: false,
  //     requireAuth: true,
  //     keepAlive: true,
  //     className: ''
  //   },
  // },
  {
    path: '/bindBank',
    name: 'BindBank',
    component: () => import('@/views/My/bindBank.vue'),
    meta: {
      level: 2.2,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/feedWork',
    name: 'FeedWork',
    component: () => import('@/views/My/feedwork.vue'),
    meta: {
      level: 2.2,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/address',
    name: 'Address',
    component: () => import('@/views/My/address.vue'),
    meta: {
      level: 2.3,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: '',
    },
  },
  {
    path: '/addAddress',
    name: 'AddAddress',
    component: () => import('@/views/My/addAddress.vue'),
    meta: {
      level: 2.4,
      footerShow: false,
      requireAuth: true,
      keepAlive: true,
      className: '',
    },
  },
];

export default router;
