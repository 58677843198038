import { get, post } from './index';

//用户信息
export const getUser = (params) => post('/user/userinfo', params);

//我的团队
export const getMyTeam = (params) => get('/user/getMyChild', params);

//修改登录密码
export const editLoginPass = (params) =>
  post('/user/updateloginpassword', params);

//修改交易密码
export const editTradPass = (params) =>
  post('/user/updatetradpassword', params);

//获取oss
export const getOssKey = () => post('/home/getOssKey');

//实名认证
export const realName = (params) => post('/user/editRealName', params);

//提交工单
export const addWork = (params) => post('/user/addonline', params);

//查看工单列表
export const getWorkList = (params) => post('/user/onlinelist', params);

//所有提现地址列表
export const getAllAddress = (params) => post('/account/toaddress',params)

//币种对应提现地址
export const getCoinAddress = (params) => post('/account/toaddress_coin',params)

//添加提现地址
export const addAddress = (params) => post('/account/add_toaddress',params)

//删除提币地址
export const deleteAddress = (params) => post('/account/remove_toaddress',params)

//收益排行榜
export const getIncomeRank = (params) => post('/min/incomeRank',params)

//算力排行
export const getPowerRank = (params) => post('/min/powerRank',params)