import { get, post } from './index';

//获取验证码
export const getCode = (params) => post('/user/code', params);

//注册
export const register = (params) => post('/member/enrollfromtel', params);

//登录
export const login = (params) => post('/member/login', params);

//验证码登录
export const codeLogin = (params) => post('/member/loginByTel',params)

//忘记密码
export const forget = (params) => post('/member/forgetfromtel', params);

//获取swiper
export const getBanner = (params) => post('/home/banner',params)

//获取公告
export const getNotice = (params) => post('/home/articles', params);

//退出登录
export const logOut = (params) => post('/user/logout',params);

//获取系统时间
export const getSystemTime = () => post('/home/systemTime');

//获取版本号
export const getVersion = (params) => post('/home/version', params);

//公告是否阅读
export const getNoticeType = () => post('/home/isNotRead');

//修改公告阅读状态
export const setNoticeType = (params) => post('/home/editRead',params);
