<script setup>
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
defineProps({
  title: String,
  back: Boolean,
  type: String,
  textAlign: String,
});
</script>
<template>
  <div class="common-title flex_center" :class="[type]" style="height: 60px">
    <span class="font-Eight font500">{{ title }}</span>
    <img
      src="@assets/commonImg/back_icon.png"
      alt=""
      class="back_icon centerAll"
      @click="back ? router.replace('/') : router.go(-1)"
    />
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.common-title {
  position: relative;
}
.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  transition: ease-in-out 0.2s;
  background: $footerBg;
}
.back_icon {
  width: 20px;
  left: 15px;
}
.right_icon {
  right: 15px;
}
</style>
