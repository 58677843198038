import axios from 'axios';

// axios.defaults.baseURL = 'http://192.168.0.144:8083';
axios.defaults.baseURL = 'https://www.aleomin.com';
// axios.defaults.baseURL = 'https://youmiapi.yuanjie.fit';
const esignAxios = axios.create({
  baseURL:'https://smlopenapi.esign.cn/'
})
import router from '@/router/index.js';
import { Toast } from 'vant';
import qs from 'qs';
import crypto from '@/utils/crypto';

axios.defaults.timeout = 15000;
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=utf-8';
// http请求拦截器
axios.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (data) => {
    const originalText = data.data;

    // let originalText = JSON.parse(crypto._jmcode2(data.data));

    if (originalText.code == '0') {
      return originalText;
    } else {
      if (originalText.code == '404') {
        localStorage.removeItem('token');
        router.replace('/login');
        return originalText;
      }
      Toast({
        message: originalText.msg,
      });
    }

    return originalText;
  },
  (error) => {
    if (error.msg) {
      Toast({
        message: error.msg,
      });
    } else {
      Toast('服务器火爆，请稍后再试！');
    }

    return Promise.reject(error);
  }
);

export function post(method, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(method, params, {
        headers: {
          locale: localStorage.getItem('locale') || 'CN',
          token: localStorage.getItem('token') || '',
          // timestamp:new Date().getTime() + +sessionStorage.getItem('deviation')
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// export function post2(method, params) {
//   const timestamp = new Date().getTime() + +sessionStorage.getItem('deviation');

//   const data = Object.assign(
//     {
//       method: method.slice(1),
//       timestamp,
//     },
//     params
//   );
//   const cipherText = JSON.stringify(data);
//   // const cipherText = crypto._jmcode1(JSON.stringify(data));
//   return new Promise((resolve, reject) => {
//     axios
//       .post('/actionMall', Qs.stringify({ params: cipherText }), {
//         headers: {
//           locale: localStorage.getItem('locale') || 'CN',
//           token: localStorage.getItem('token') || '',
//         },
//       })
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// }

export function get(method, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(method, {
        params,
        headers: {
          locale: localStorage.getItem('locale') || 'CN',
          token: localStorage.getItem('token') || '',
          // timestamp:new Date().getTime() + +sessionStorage.getItem('deviation'),
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getSignDemo = (url,params) => {
  return new Promise((resolve,reject) => {
    esignAxios.get(url,{
      params,
      // headers:{
      //   'X-Tsign-Open-App-Id':'7438944236',
      //   'X-Tsign-Open-Auth-Mode': '357f27513ec373cec4f9dedc5dc50575',
      //   'X-Tsign-Open-Ca-Signature':'uxydqKBMBy6x1siClKEQ6Q==',
      //   Accept: '/',
      //   'Content-Type' : 'application/json; charset=UTF-8',
      //   'X-Tsign-Open-Ca-Timestamp':Date.now()

      // }
    }).then((res) => {
      resolve(res)
    })
  })
}