import { defineStore } from 'pinia';
import { setTimeDiff } from '@/utils/index.js';
import {
  getBanner,
  getSystemTime,
  getNoticeType,
  setNoticeType,
} from '@/http/account';
import { getUser } from '@/http/my';
export const commonStore = defineStore('common', {
  state: () => {
    return {
      footerActiveIndex: 0,
      userInfo: {},
      parent: sessionStorage.getItem('code') || '',
      versionData: {},
      version: 1.6,
      isAppUpdate: false,
      bannerArr: [],
      noticeArr: [],
      noticeState: null,
      informationState: null,
      menuShow: false,
      barHeight: 0,
      cacheTime: {
        banner: -1,
        userInfo: -1,
        system: -1,
      },
    };
  },
  getters: {
    systemTime: (state) => {
      return new Date().getTime() + state.deviation;
    },
  },
  actions: {
    getUserInfo(bool) {
      if (
        !bool &&
        this.cacheTime.userInfo &&
        !setTimeDiff(this.cacheTime.userInfo, 6000)
      ) {
        return;
      }
      getUser({ token: localStorage.getItem('token') }).then((res) => {
        if (res.code == 0) {
          this.userInfo = res.data;
          this.cacheTime.userInfo = new Date().getTime();
        }
      });
    },
    getNoticeState() {
      getNoticeType().then((res) => {
        if (res.code == 200) {
          this.noticeState = res.data.isNotNotice;
          this.informationState = res.data.isNotBriefed;
        }
      });
    },
    changeNoticeState(type) {
      setNoticeType({ type }).then((res) => {
        if (res.code == 200) {
          if (type == 'notice') {
            this.noticeState = true;
          } else {
            this.informationState = true;
          }
        }
      });
    },
    getBanners() {
      if (this.cacheTime.banner && !setTimeDiff(this.cacheTime.banner, 60000)) {
        return;
      }
      getBanner({ type: 'wap' }).then((res) => {
        if (res.code == 0) {
          this.bannerArr = res.data;
          this.cacheTime.banner = new Date().getTime();
        }
      });
    },
    setVersionInfo(info) {
      if (info.code != this.version) {
        this.isAppUpdate = true;
      }
      this.versionData = info;
    },

    getNowTime() {
      if (
        this.cacheTime.system &&
        !setTimeDiff(this.cacheTime.system, 300000)
      ) {
        return;
      }
      // getSystemTime().then((res) => {
      //   if (res.code == 200) {
      //     this.systemTimeNow = res.data;
      //     this.cacheTime.system = new Date().getTime()
      //     this.deviation = this.systemTimeNow - new Date().getTime();
      //     sessionStorage.setItem('deviation', this.deviation);
      //   }
      // });
    },

    setFooterIndex(index) {
      this.footerActiveIndex = index;
    },

    loginInfo(obj) {
      this.userInfo = obj;
    },
    logOut() {
      sessionStorage.clear();
      localStorage.removeItem('token');
      this.noticeState = null;
      this.informationState = null;
    },
  },
});
