import { getVersion } from '@/http/account';
let ajaxCount = 0; //设置初始请求次数，请求更新的接口有可能会失败 导致没法强制更新；
let dtask = null; //下载任务
import pinia from '../store/index.js';
import { commonStore } from '@/store/common';
const common_store = commonStore(pinia);
import router from '@/router/index';

//设置顶部栏样式
const setBarColor = () => {
  // plus.navigator.setStatusBarBackground('#1A1A1A');
  // plus.navigator.setStatusBarStyle('dark');
  var main = plus.android.runtimeMainActivity();
	var windowMe = main.getWindow();
	plus.android.importClass(windowMe);
	var Color = plus.android.importClass("android.graphics.Color");
	//如parseColor("#ffffff")设置为白色
	plus.android.invoke(windowMe,"setNavigationBarColor",Color.parseColor("#e4e4f7"));

};

//检查更新
const checkUpdate = () => {
  getVersion({ type: 'android' }).then((res) => {
    ajaxCount = 0;
    if (res.code == 0) {
      common_store.setVersionInfo(res.data[0]);
    }
  });
};

// 处理document 监听事件
const handleListener = () => {
  let timeObj = null;
  //监听网络状态
  document.addEventListener(
    'netchange',
    () => {
      var nt = plus.networkinfo.getCurrentType();
      //获取历史状态
      var lastNt = sessionStorage.getItem('lastNt') || nt;
      timeObj && window.clearTimeout(timeObj);
      //加延时是为了避免无线wifi切数据时会插入一个无网络状态所造成的判断错误
      timeObj = setTimeout(() => {
        //更新历史状态
        sessionStorage.setItem('lastNt', nt);
        //网络状态为 0 或 1 时无网络服务
        if (nt == 1 || nt == 0) {
          mui.toast('目前没有网络服务，请检查网络连接！', '');
        } else if (lastNt == 1 || lastNt == 0) {
          //历史状态为 0 或 1 时 表示网络状态由无到有
          console.log('重新连接网络');
          window.location.reload();
        }
      }, 3000);
    },
    false
  );

  //监听app进入前台
  document.addEventListener(
    'resume',
    () => {
      //如果没有下载安装包任务 版本号也不一致 就再次请求接口确认
      if (!dtask && common_store.versionData.code != common_store.version) {
        checkUpdate();
      }
    },
    false
  );
};

//处理页面返回
const handleBack = () => {
  let quitTime = null;
  mui.back = function () {
    //首次按键，提示‘再按一次退出应用’
    let route = router.currentRoute.value;

    if (!quitTime) {
      if ( ['Home', 'Market', 'Compose', 'My', 'Login'].includes(route.name)) {
 
        //记录第一次按下回退键的时间
        quitTime = Date.now();
        mui.toast('再按一次退出程序', '');
        setTimeout(function () {
          //1s中后清除
          quitTime = null;
        }, 1000);
        
      }  else {
        //不是主界面才开启物理键回退到上一页面
        window.history.go(-1);
       
      }
    } else {
      console.log('quittime2222',Date.now() - quitTime)
     
      if (Date.now() - quitTime < 1000) {
        quitTime = null;
        if (
          ['Home', 'Market', 'Compose', 'My', 'Login'].includes(route.name)
        ) {
          //只有在主界面才开启2次回退关闭app
          plus.runtime.quit();
        }
      }
    }
  };
};

//设置状态栏高度
const getBarHeight = () => {
    common_store.barHeight = plus.navigator.getStatusbarHeight();
    console.log(common_store.barHeight);

}

document.addEventListener(
  'plusready',
  function () {
    mui.plusReady(() => {
      //检查更新
      checkUpdate();
      //设置顶部栏样式
      setBarColor();

      getBarHeight()
      //处理页面返回
      handleBack();

      //处理监听
      handleListener();
    });
  },
  false
);
