export const isPassword = (data) => {
  let pattern = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,12}$/;
  if (data == '') return false;
  return pattern.test(data);
};
export const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const getBase64 = (url, callback) => {
  let canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d'),
    img = new Image();
  img.crossOrigin = 'anonymous'; //解决Canvas.toDataURL 图片跨域问题
  img.onload = () => {
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase(); // 获取到图片的格式
    let dataURL = canvas.toDataURL('image/' + ext); // 得到base64 编码的 dataURL
    callback && callback(dataURL);
    canvas = null;
  };
  img.src = url;
};
export const setImages = (str) => {
  return str.split(',');
};

export const getImage = (str) => {
  const arr = str.split(',');
  return arr[0];
};
export const getVideoImage = (str) => {
  const arr = str.split(',');
  return arr[1];
};

export const setTimeDiff = (oldStr, timeStr = 30000) => {
  const newDate = new Date().getTime();
  return newDate - oldStr > timeStr;
};

export const getHasMan = (idCard) => {
  const cardStr = idCard.slice(6, 14);
  const idYear = cardStr.slice(0, 4);
  const idMonth = cardStr.slice(4, 6);
  const idDay = cardStr.slice(6);
  const time = new Date();
  const year = time.getFullYear();
  const month = time.getMonth() + 1;
  const day = time.getDate();
  // console.log(year, month, day, idYear, idMonth, idDay);
  if (+idYear + 18 < year) {
    return true;
  }
  if (+idMonth < +month && +idYear + 18 <= year) {
    return true;
  }

  if (+idMonth <= +month && idDay <= day && +idYear + 18 <= year) {
    return true;
  }

  return false;
};

export const getTime = (str) => {
  const date = new Date(str * 1000);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const Hour = date.getHours();
  const minutes = date.getMinutes();
  return `${month}-${day} ${Hour}:${minutes}`;
};

const ff = (num) => {
  return num < 10 ? `0${num}` : num;
};

export const getFullTime = (str) => {
  const date = new Date(str * 1000);
  const year = date.getFullYear();
  const month = ff(date.getMonth() + 1);
  const day = ff(date.getDate());
  const Hour = ff(date.getHours());
  const minutes = ff(date.getMinutes());
  return `${year}-${month}-${day} ${Hour}:${minutes}`;
};

//个人等级
const levelArr = [
  {
    title: '销售代理',
    level: 0,
    profit: '15%',
    number: '15万',
  },
  {
    title: '县级代理',
    level: 1,
    profit: '23%',
    number: '10万',
  },
  {
    title: '市级代理',
    level: 2,
    profit: '30%',
    number: '30万',
  },
  {
    title: '省级代理',
    level: 3,
    profit: '35%',
    number: '100万',
  },
  {
    title: '区域总代',
    level: 4,
    profit: '37%',
    number: '500万',
  },
];
export const filterLevel = (level) => {
  return levelArr[level].title;
};

export const infoLevel = (level) => {
  return levelArr[level];
};
