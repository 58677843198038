// import * as vueRouter from 'vue-router';
import { createRouter, createWebHashHistory } from 'vue-router';
const Register = () => import('@/views/Account/register.vue')
import routers from './routers';
const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      level: 3.1,
      footerShow: false,
      requireAuth: false,
      keepAlive: true,
      className: 'bg2'
    },
  },
  {
    path: '/:cathchAll(.*)',
    name: '404',
    component: () => import('@/views/404.vue')
  },
  ...routers,

 
];
// export default router;
export default createRouter({
  history: createWebHashHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return { top:  0 };
  },
});
