<script setup>
import { ref, reactive } from 'vue';
const safeArr = [
  {
    title: '个人中心',
    link: '/userSet',
    icon: require('@assets/myImg/icon1.png'),
  },
  {
    title: '安全中心',
    link: '/set',
    icon: require('@assets/myImg/icon2.png'),
  },
];

const toolArr = [
  {
    title: '邀请好友',
    link: '/invite',
    icon: require('@assets/myImg/icon3.png'),
  },
  {
    title: '消息通知',
    link: '/notice',
    icon: require('@assets/myImg/icon4.png'),
  },
  {
    title: '提交工单',
    link: '/feedWork',
    icon: require('@assets/myImg/icon5.png'),
  },
];
</script>

<template>
  <div>
    <ul class="nav-ul Wbg padding">
      <li
        v-for="(item, key) in safeArr"
        :key="key"
        class="flex_between"
        @click="$router.push(item.link)"
      >
        <img :src="item.icon" alt="" class="nav_icon" />
        <span class="font-Five f1 marginTen">{{ item.title }}</span>
        <img src="@assets/myImg/row_right.png" alt="" class="row_right" />
      </li>
    </ul>
    <ul class="nav-ul Wbg padding">
      <li
        v-for="(item, key) in toolArr"
        :key="key"
        class="flex_between"
        @click="$router.push(item.link)"
      >
        <img :src="item.icon" alt="" class="nav_icon" />
        <span class="font-Five f1 marginTen">{{ item.title }}</span>
        <img src="@assets/myImg/row_right.png" alt="" class="row_right" />
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.nav-ul {
  border-radius: 8px;
  margin: 20px 0;
  li {
    height: 50px;
  }
  li:not(:last-child) {
    border-bottom: 1px solid rgba($blockColor, 0.08);
  }
}
.row_right {
  width: 5px;
  height: 8px;
}
.nav_icon {
  width: 25px;
  height: 25px;
}
</style>
