<script setup>
import {
  onBeforeMount,
  onMounted,
  ref,
  watch,
  watchEffect,
  nextTick,
  provide,
} from "vue";
import commonFooter from "./components/commonFooter.vue";
import appUpdata from "./components/appUpdata.vue";
import { useRoute, useRouter } from "vue-router";
import { commonStore } from "@/store/common";
import { useScrollParent, useEventListener } from "@vant/use";
import My from "./views/My/index.vue";
const common_store = commonStore();
const route = useRoute();
const router = useRouter();
const routeName = ["Home", "Assets", "Invite", "MarketDetail"];
const transitionName = ref("slide-left");
const dom = ref(null);
const scrollParent = useScrollParent(dom);
let scrollTop = ref(0);
let includeList = ["Home"];
const getInviteCode = (num) => {
  let url = num.slice(num.lastIndexOf("?") + 1); //截参数
  let arr = url.split("&");
  let obj = {};

  if (arr.length > 0) {
    arr.forEach((item, key) => {
      //循环获取参数
      const params = item.split("=")[1];
      const name = item.split("=")[0];
      obj[name] = params;
    });
  }

  console.log(obj);
  //邀请码
  if (obj.code) {
    sessionStorage.setItem("code", obj.code);
    common_store.parent = obj.code;
  }
};
const isRouterAlive = ref(true);
const reload = () => {
  isRouterAlive.value = false;
  nextTick(() => {
    isRouterAlive.value = true;
  });
};
provide("reload", reload);
const scrollEvent = () => {
  scrollTop.value = window.pageYOffset;
  // route.meta.scrollTop = scrollTop.value;
  // console.log(scrollTop.value);
};

onBeforeMount(() => {
  // common_store.getNowTime();
  if (localStorage.getItem("token")) {
    common_store.getUserInfo();
  }
  getInviteCode(window.location.href);
  sessionStorage.setItem("myPopup", 0);
  window.addEventListener("scroll", scrollEvent, { passive: true });
});

watch(
  () => route,
  (to, from) => {
    if (+to.meta.level <= +from.meta.level) {
      transitionName.value = "slide-right";
    } else {
      transitionName.value = "slide-left";
    }
  }
);
</script>
<template>
  <van-config-provider>
    <div class="app" id="appDom" ref="dom">
      <div
        :style="{ height: common_store.barHeight + 'px' }"
        v-if="common_store.barHeight && !routeName.includes(route.name)"
      ></div>
      <div
        class="fixBar Wbg"
        :style="{ height: common_store.barHeight + 'px' }"
        v-if="common_store.barHeight && !routeName.includes(route.name)"
      ></div>

      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transitionName">
          <keep-alive :include="includeList">
            <component
              :is="Component"
              :key="route.name"
              v-if="isRouterAlive"
              :scrollTop="scrollTop"
              class="Wbg"
              :style="{
                minHeight: `calc(100vh - ${common_store.barHeight}px)`,
              }"
            />
          </keep-alive>
        </transition>
      </router-view>

      <van-popup
        v-model:show="common_store.isAppUpdate"
        class="app-updata"
        :close-on-click-overlay="false"
      >
        <app-updata></app-updata>
      </van-popup>
      <common-footer v-if="route.meta.footerShow"></common-footer>
    </div>

    <div class="app-bg" :class="route.meta.className"></div>
    <van-popup
      v-model:show="common_store.menuShow"
      position="left"
      class="menu-popup"
      style="height: 100vh"
      :close-on-popstate="false"
    >
      <div class="my-popup Wbg">
        <My />
      </div>
    </van-popup>
  </van-config-provider>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overflow: hidden;
  color: $blockColor;
  // background: #f7f7f7;
}
.app {
  min-height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 99;
}
.app-bg {
  position: fixed;
  display: inline-block;
  z-index: 9;
}
.fixBar {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
}
.bg1 {
  background: $mainColor;
  width: 150px;
  height: 150px;
  top: 48px;
  left: 10px;
  filter: blur(50px);
}

.bg2 {
  background: linear-gradient(to bottom, $mainColor 50%, #d2d2fa 50%);
  width: 70%;
  height: 60px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  filter: blur(55px);
  z-index: 99;
}

@keyframes moveRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes moveLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/* .slide-left {
  animation: 0.2s moveRight linear;
}
.slide-right {
  animation: 0.2s moveLeft linear;
} */
.slide-left-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-enter-active,
.slide-left-lever-active {
  transition: linear 0.2s;
}

.slide-left-leave-from,
.slide-left-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.slide-right-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-right-enter-active,
.slide-right-lever-active {
  transition: linear 0.2s;
}

.slide-right-leave-from,
.slide-right-enter-to {
  opacity: 1;
  transform: translateX(0);
}
</style>
