import { createApp } from 'vue';
import App from './App.vue';
import './utils/plus';
import router from './router';
import commonTitle from './components/commonTitle.vue';
import pinia from './store/index.js';
import noRecord from './components/noRecord.vue';
import titleText from './components/titleText.vue';
import saveCode from './components/saveCode.vue';
import 'amfe-flexible/index.js';
import './assets/css/common.scss';
import './assets/css/vant.scss';

import { commonStore } from '@/store/common';
const common_store = commonStore(pinia);
import {
  Button,
  ConfigProvider,
  Toast,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Popup,
  Overlay,
  Tab,
  Tabs,
  Uploader,
  Popover,
  List,
  Dialog,
  Stepper,
  Switch,
  NoticeBar,
  ImagePreview,
  Lazyload,
  PasswordInput,
  NumberKeyboard,
  SwipeCell
} from 'vant';

// router.beforeResolve((to, from) => {
//   if (sessionStorage.getItem('myPopup') > 0) {
//     sessionStorage.setItem('myPopup', 0);
//     return false;
//   }
// });

router.beforeEach((to, from, next) => {
  if (to.name == 'Home' && sessionStorage.getItem('myPopup') > 0) {
    common_store.menuShow = true;
  } else {
    common_store.menuShow = false;
  }
  if (to.meta.child) {
    next();
    return;
  }
  to.meta.transitionName =
    +to.meta.level <= +from.meta.level ? 'slide-right' : 'slide-left';

  if (localStorage.getItem('token')) {
    next();
  } else if (!to.meta.requireAuth) {
    next();
  } else {
    if (
      sessionStorage.getItem('code') &&
      to.path != '/register' &&
      from.path != '/register'
    ) {
      next({ path: '/register' });
    } else if (
      !sessionStorage.getItem('code') &&
      to.path != '/login' &&
      from.path != '/login'
    ) {
      next({ path: '/login' });
    } else {
      next();
    }
  }
});

const app = createApp(App);
app.config.errorHandler = (err, vm, info) => {
  console.log('[全局警告]', err, vm, info);
};
app
  .use(router)
  .use(pinia)
  .use(Button)
  .use(Swipe)
  .use(ConfigProvider)
  .use(SwipeItem)
  .use(VanImage)
  .use(Popup)
  .use(Overlay)
  .use(Tab)
  .use(Tabs)
  .use(Uploader)
  .use(List)
  .use(Popover)
  .use(Dialog)
  .use(Stepper)
  .use(Toast)
  .use(Switch)
  .use(NoticeBar)
  .use(ImagePreview)
  .use(Lazyload)
  .use(PasswordInput)
  .use(NumberKeyboard)
  .use(SwipeCell)
  .component('commonTitle', commonTitle)
  .component('noRecord', noRecord)
  .component('titleText', titleText)
  .component('saveCode', saveCode)
  .mount('#app');
