<script setup>
import { ref, reactive, toRefs, markRaw } from "vue";
import { getCode } from "@/http/account.js";
import { Toast } from "vant";
const props = defineProps({
  type: String,
  tel: String,
  areaCode: String,
  token: Boolean,
});
let timer = ref(null);
let time = ref(60);
let updata = ref(false);
const { type, tel, areaCode, token } = toRefs(props);
const saveCode = () => {
  if (timer.value) {
    return;
  }
  if (!tel.value && !token.value) {
    Toast("请填写手机号");
    return;
  }
  const params = markRaw({
    type: type.value,
    token: tel.value || localStorage.getItem("token"),
    areacode: areaCode.value || "86",
  });
  getCode(params).then((res) => {
    if (res.code == 0) {
      timeDown();
      Toast(res.msg);
    }
  });
};

const timeDown = () => {
  if (timer.value) {
    clearInterval(timer.value);
  }
  updata.value = true;
  timer.value = setInterval(() => {
    if (time.value == 0) {
      updata.value = false;
      clearInterval(timer.value);
      timer.value = null;
      time.value = 60;
    }
    time.value--;
  }, 1000);
};
const ff = (val) => {
  return val < 10 ? `0${val}` : val;
};
</script>
<template>
  <div class="save flex_center Wcolor center font500" @click="saveCode">
    {{ updata ? ff(time) : "发送" }}
  </div>
  <!-- <van-button type="default" size="mini" class="save">获取验证码</van-button> -->
</template>

<style lang="scss" scoped>
.save {
  display: inline-block;
  background: linear-gradient(138deg, #4e02ff, #2582e1);
  min-width: 60px;
}
</style>
