<script setup>
import { ref, reactive, watchEffect } from "vue";
import { commonStore } from "@/store/common";
const common_store = commonStore();
const data = reactive({
  showProgress: false,
  downloadProgress: 0,
  dtask: null,
});

const createDownloadTask = () => {
  if (mui.os.android) {
    data.showProgress = true;
    data.dtask = plus.downloader.createDownload(
      common_store.versionData.url,
      {},
      (d, status) => {
        if (status == 200) {
          installPackage(d.filename);
          plus.runtime.quit();
        } else {
          plus.nativeUI.alert("安装包下载失败！", function () {}, "", "ok");
        }
      }
    );

    data.dtask.addEventListener("statechanged", onStateChanged, false);
    data.dtask.start();
  } else {
    plus.runtime.openURL(common_store.versionData.url);
    plus.runtime.restart();
  }
};

const onStateChanged = () => {
  mui.later(() => {
    let num = data.dtask.downloadedSize / data.dtask.totalSize;
    if (num && num < 1) {
      data.downloadProgress = num;
    }
  }, 200);
};

const installPackage = (path) => {
  plus.runtime.install(
    path,
    {},
    function () {
      plus.nativeUI.closeWaiting();
      console.log("安装wgt文件成功！");
      setTimeout(() => {
        plus.nativeUI.alert(
          "安装后请重新打开应用程序！",
          function () {
            plus.runtime.restart();
          },
          "",
          "ok"
        );
      }, 3000);
    },
    function (e) {
      plus.nativeUI.closeWaiting();
      console.log("安装wgt文件失败[" + e.code + "]：" + e.message);
      plus.nativeUI.alert(
        "更新资源失败 [" + e.code + "]：" + e.message,
        function () {},
        "",
        "ok"
      );
    }
  );
};
</script>
<template>
  <div class="update_box">
    <div class="update_top_img Wcolor">
      <img src="@assets/commonImg/version_top.png" alt="" />
      <p>V1.{{ common_store.versionData.code }}</p>
    </div>
    <div class="update_bottom_box blockColor">
      <h3 class="Bold">APP版本更新</h3>
      <p class="update_text">1.修复了一些已知问题</p>
      <p class="update_text">2.优化细节</p>
      <div class="update_btn" v-show="!data.showProgress">
        <span class="Wcolor font-Fvie Bold center" @click="createDownloadTask()"
          >立即更新</span
        >
      </div>
      <div class="download_progress_box" v-show="data.showProgress">
        <h4>正在下载 ({{ Math.floor(data.downloadProgress * 100) }}%)</h4>
        <progress :value="data.downloadProgress"></progress>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.update_box {
  width: calc(100vw - 100px);
  background-color: transparent;
  .update_top_img {
    position: relative;
    font-size: 0;
    p {
      position: absolute;
      right: 10px;
      top: 40px;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .update_bottom_box {
    background-color: $white;
    margin-top: -1px;
    padding: 20px 20px 30px;
    border-radius: 0 0 10px 10px;
    .update_text {
      font-size: 12px;
      margin: 15px 0;
    }
    .update_btn {
      margin-top: 40px;
      span {
        display: block;
        height: 40px;
        line-height: 40px;
        border-radius: 25px;
        background: linear-gradient(
          132deg,
          rgba(126, 122, 232, 0.95),
          rgba(38, 31, 211, 0.95)
        );
      }
    }
  }

  .download_progress_box {
    height: 44px;
    h4 {
      font-size: 12px;
      color: $mainColor;
      margin-bottom: 5px;
    }
    progress {
      width: 100%;
      border-radius: 30px;
      height: 5px;
      vertical-align: middle;
      &::-webkit-progress-bar {
        background-color: $white;
        border-radius: 30px;
      }
      &::-webkit-progress-value {
        background: $mainColor;
        border-radius: 30px;
      }
    }
  }
}
</style>
