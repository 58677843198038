<script setup>
import { ref, reactive, toRefs } from "vue";
import useClipboard from "vue-clipboard3";
import { Toast } from "vant";
const { toClipboard } = useClipboard();
const props = defineProps({
  info: Object,
});
const { info } = toRefs(props);
const copyLink = async () => {
  try {
    await toClipboard(info.value.uuid);
    Toast("复制成功");
  } catch (e) {
    Toast("复制失败");
  }
};
</script>

<template>
  <ul class="flex_between mine-info">
    <li class="allFlex column">
      <p class="font-Tt Bold">Hello,{{ info?.member }}</p>
      <div class="flex_start id_info">
        <p class="font-Two">UID:{{ info?.uuid }}</p>
        <img
          src="@assets/myImg/copy_icon.png"
          alt=""
          class="copy_icon marginTen"
          @click="copyLink"
        />
      </div>
    </li>
    <li class="imgBox flex_center mine-info_head">
      <img src="@assets/myImg/head.png" alt="" />
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.mine-info {
  height: 120px;

  &_head {
    width: 56px;
  }
  .id_info {
    line-height: 26px;
  }
}

.copy_icon {
  width: 14px;
  height: 14px;
}
</style>
