import CryptoJS from 'crypto-js';
const key = CryptoJS.enc.Utf8.parse('juyhg783ki9ftkr5');
const iv = CryptoJS.enc.Utf8.parse('juyhg783ki9ftkr5');

const utils = {
  // AES 加密
  _jmcode1(data) {
    let msg = CryptoJS.enc.Utf8.parse(data);
    let encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString().toUpperCase();
  },

  //AES解密
  _jmcode2(data) {
    let encryptedHexStr = CryptoJS.enc.Hex.parse(data);
    let msg = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(msg, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  },
};
export default utils;
